

/* width */
::-webkit-scrollbar {
    width: 5dvw;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: radial-gradient(circle at top, #050100 10%, #83b6b8 70%,  #83b6d7 90%); /* change the background color asS needed */
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: wheat; 
  }


  @-webkit-keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}


@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

  

body {
    // background-color: #83b6d7; /* change the background color as needed */
    background: radial-gradient(circle at top, #050100 10%, #83b6b8 20%,  #83b6d7 70%); /* change the background color asS needed */
    background-size: auto 2000%;
    overscroll-behavior-x: none;
    // overflow-x: hidden;
    font-family: 'Helvetica';
}

    $n: 6; // Number of images
    $item-width:300px; // Width of an image. In the Js version this value can be a percentage
    $item-separation: 40px; // The space between the images. This will decrease the effective item width
    $viewer-distance: 1000px;

    // Derived variables
    $theta: calc( 2 * 3.141592653589793 / $n);

    .grid-container {
        display: grid;
        grid-template-columns: repeat(8, 7dvw); /* Adjust width of each column */
        grid-template-rows: repeat(8, 13dvh); /* Adjust height of each row */
        gap: 1px; /* Adjust the gap between grid items */

    }
    
    .grid-item{
        border: 1px solid white; /* Border color and width of grid items */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem; /* Adjust font size of content */
    }

    .edge-item img{
        border: 1px solid white; /* Border color and width of grid items */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem; /* Adjust font size of content */

    }

    .grid-container .grid-item{
        overflow:hidden;
        
    }

    .carousel-title {
        color: white;
    }
    
    .arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid #000;
        position: absolute;
        top: 40%; /* Initial vertical position */
        left: 50%; /* Initial horizontal position */
        rotate: 25deg;
        transform: translate(-50%, -50%); /* Center the arrow */
      }

      #hideBtn {
        position: absolute;
        z-index: 10000;
        font-size: 40px;
        font-family: Helvetica;
    }

    .tutorial{
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 90%;
        left: 20%;
    }
    /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 10000;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 10dvh;
    width: 5dvw;
    left: 2dvw;
    bottom: 2dvh;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color:  #83b6b8;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #91c9f6;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(8dvw);
    -ms-transform: translateX(8dvw);
    transform: translateX(11dvw);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
      
    
    .carousel-container {
        position: relative;
        z-index: 1;
        flex-direction: column;
        justify-content: center;
        user-select: none;
        cursor: crosshair;
    }


    .carousel-list {
        perspective: $viewer-distance;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 13%;
        width: 100dvw;
        

    > * {
        flex: 0 0 auto;
    }
    figure {
            will-change: transform, transition;    
            margin: 0;
            transform-style: preserve-3d;
            -webkit-transition: transform .5s ease;;
            backface-visibility:hidden;
        

            
            


            

            .carousel-item {
                width: 100dvw;
                box-sizing: border-box;
                padding: 0 calc($item-separation / 2);
                backface-visibility: hidden;

                





                
                // 
            //     img {
            //         width: $item-width;
            //         overflow: visible;

                                
            // 	&:not(:first-of-type) {
            // 		position: absolute;
            // 		left: 0;
            // 		top: 0;
            //         transition: 50% 50% (-$apothem);

            // 	}
            // }
            //     @for $i from 2 through $n {
            //         &:nth-child(#{$i}) {
            //             -webkit-transform: rotateY(#{($i - 2) * $theta}rad);
        
            //         }
            //     }


                img {
                    width: $item-width;
                    transform-style: preserve-3d;
                    
                }


                
                &:not(:first-of-type) {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
       
                // @for $i from 1 through $n {
                //     &:nth-child(#{$i}) {
                //         -webkit-transform: rotateY(#{($i - 8.5) * $theta}rad);
                //         transform: rotateY(#{($i - 8.5) * $theta}rad);
                //     }
                // }
                
            }
        }
        }

        nav {
            display: flex;
            justify-content: center;
        }




    .content-container {
        z-index: 1;
        position: absolute;
        top: 95%;
        margin-top: 10%;

    }



        .carousel-item p {
            // transform-origin: 50% 50% 0;
            color: rgb(244, 232, 221);
            position: absolute; 
            font-size: 100px;
            bottom: 0%;
            align-content: center;
        }
        
        .carousel-item.selected p {

            overflow: visible;
            position: absolute; 
            font-size: 200px;
            left: -420px;
            bottom: -80%;
            color: rgb(244, 232, 221);
            transform: translateX(500px) translateY(180px);
        }

        @keyframes gradientAnimation {
            0% {
              background-position: 10% 50%;
            }
            50% {
              background-position: 300% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }

        .carousel-list:hover {  
 
        }

        .carousel-list:hover .carousel-item.selected{
            backface-visibility: visible;
        }

        .carousel-list:hover .carousel-item.selected img{
            -webkit-transform: scale(1.4);
                -ms-transform: scale(1.4);
                    transform: scale(1.4);

                  
          }

          .carousel-item.selected img {
            border: 24px solid turquoise; /* Adjust border thickness and color as desired */
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
            transition: border-color 0.3s ease-in-out; /* Adds a smooth transition effect */


          }

        .gis-view {
            display: grid;
        }
        

@media (width <= 800px) {
        // Carousel configuration parameters
    $n: 6; // Number of images
    $item-width:200px; // Width of an image. In the Js version this value can be a percentage
    $item-separation: 90px; // The space between the images. This will decrease the effective item width
    $viewer-distance: 600px;

    // Derived variables
    $theta: calc( 2 * 3.141592653589793 / $n);

    body {
        // background-color: #83b6d7; /* change the background color as needed */
        background: radial-gradient(circle at top, #050100 .1%, #83b6b8 10%,  #83b6d7 70%); /* change the background color asS needed */
        background-size: auto 2000%;
        // overscroll-behavior-x:none;
        overflow-x: hidden;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(8, 14dvw); /* Adjust width of each column */
        grid-template-rows: repeat(8, 14dvh); /* Adjust height of each row */
        gap: 1px; /* Adjust the gap between grid items */
        width: 100dvw;
        height: 100dvh;
    }

    #hideBtn {
        font-size: 20px;
        top: 60dvh;
    }


    .tutorial{
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 80%;
        left: 20%;
    }
      

    .arrow {
        z-index: 100000;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid #000;
        position: absolute;
        top: 20%; /* Initial vertical position */
        left: 50%; /* Initial horizontal position */
      }


    .carousel-container {
        display: flex;
        position: sticky;
        z-index: 1;
        flex-direction: column;
        justify-content: center;
        user-select: none;
        cursor: crosshair;
        overflow: visible;
        touch-action: none;

    }


    .carousel-list {
        perspective: $viewer-distance;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        // position: absolute;
        width: 100dvw;
        // left: 0%;
        // top:100%;
        

    > * {
        flex: 0 0 auto;
    }
    figure {
            will-change: transform, transition;    
            margin: 0;
            transform-style: preserve-3d;
            -webkit-transition: transform .5s ease;;
            width: 100vw;
            
            

            

            .carousel-item {
                width: 100vw;
                box-sizing: border-box;
                padding: 0 calc($item-separation / 2);
                backface-visibility: hidden;







                img {
                    width: $item-width;
                    transform-style: preserve-3d;



                }


                
                &:not(:first-of-type) {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
       
                
            }
        }
        }

        nav {
            display: flex;
            justify-content: center;
        }




    .content-container {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 85%;
        overflow: hidden;
    }

    .deckGL-view {
        width: 600px;
    }

    .carousel-list:hover .carousel-item.selected  {
        //   -webkit-transform: scale(1.4);
        //       -ms-transform: scale(1.4);
                  transform: scale(1.4);
                    transform-style: preserve-3d;
                    // overflow: hidden;
                    // width:  $item-width*1.5;
        }

    .carousel-item.selected p {

        position: absolute; 
        font-size: 50px;
        left: 0;
        bottom: -25%;
        color: rgb(244, 232, 221);
        transform: translateX(60px) translateY(100px);
        overflow: visible;
        visibility: visible;
        display:initial;


    }

    .carousel-item p {
        position: absolute; 
        color: rgb(244, 232, 221);
        font-size: 50px;
        bottom: 0%;
        align-content: center;
        display: none;

    }
}   

  
  @keyframes cubeRotate {
    from {
        transform:  rotateY(0deg);
    }
    to {
        transform: rotateY(-360deg);
    }
    to {
        transform: rotateY(-720deg);
    }
  }

.carousel-item.selected img  {


      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 100px;

    //   transform: scale(1.2);
    //       -ms-transform: scale(1.2);
    //           transform: scale(1.2);
    // -webkit-animation: cubeRotate 10s linear infinite;
    animation: cubeRotate 5s linear infinite;
    // transform-origin: 50% 50% (-$apothem);


}

.carousel-item img  {



    border-radius: 100px;


}

// .carousel-item.selected img:before {
//     content: '';
//     background: -o-linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
//     background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
//     position: absolute;
//     top: -2px;
//     left:-2px;
//     background-size:400%;
//     z-index: -1;
//     -webkit-filter: blur(5px);
//             filter: blur(5px);
//     width: calc(100% + 4px);
//     height: calc(100% + 4px);
//     -webkit-animation: glowing 20s linear infinite;
//             animation: glowing 20s linear infinite;
//     opacity: 0;
//     -webkit-transition: opacity .3s ease-in-out;
//     -o-transition: opacity .3s ease-in-out;
//     transition: opacity .3s ease-in-out;
//     border-radius: 10px;
// }

// .carousel-item.selected img:active {
//     color: #000
// }

// .carousel-item.selected img:active:after {
//     background: transparent;
// }

// .carousel-item.selected img:hover:before {
//     opacity: 1;
// }

// .carousel-item.selected img:after {
//     z-index: 0;
//     content: '';
//     // position: absolute;
//     background: #111;
//     // left: 0;
//     // top: 0;
//     border-radius: 10px;
// }


// .parallax-image {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     /* overflow: hidden; */
//   }
  
//   .parallax-image img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     -webkit-transition: -webkit-transform 0.2s ease-out;
//     transition: -webkit-transform 0.2s ease-out;
//     -o-transition: transform 0.2s ease-out;
//     transition: transform 0.2s ease-out;
//     transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
//   }

//   .mouse-parallax {
//     -webkit-transition: -webkit-transform 0.2s ease-out;
//     transition: -webkit-transform 0.2s ease-out;
//     -o-transition: transform 0.2s ease-out;
//     transition: transform 0.2s ease-out;
//     transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
//   }

